
.promo {
    background: url('./bg_green.png');
    border-radius: 10px;
    margin: 0 auto;
    width: 90%;
    max-width: 1600px;
    
    &__wrapper {
        padding: 10px 0;
        text-align: left;
        h1 {
            font-weight: 600;
            width: 80%;
            font-size: 40px;
            color: white;
            line-height: 1.5em;
            span {
                background-color: orange;
                border-radius: 10px;
                padding: 0 3px 6px 3px;
                display: inline-block;
                vertical-align: middle;
            }
        }
        h2 {
            font-weight: 400;
            width: 50%;
            font-size: 20px;
            color: white;
        }
    }
}

// Мобильные устройства
@media (max-width: 768px) {
    .promo {
        display: none;
        &__wrapper {
            h1 {
                font-size: 1.5rem;
            }
            h2 {
                font-size: 1rem;
            }
        }
    }

}

// Адаптация для планшетов
@media (max-width: 1024px) {
    .promo {
        &__wrapper {
            text-align: center;
            h1 {
                width: 100%;
            }
            h2 {
                width: 100%;
            }
        }
    }
}