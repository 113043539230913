.checkbox {
    margin-top: 20px;
    width: 30%;
    background-color: white;
    border-radius: 10px;
    height: auto;

    &__wrapper {
        padding: 20px 60px;
        text-align: left;

        h2 {
            font-weight: 600;
        }

        &-divider {
            margin-top: 10px;
            border-top: 2px solid #bbb;
            margin-bottom: 20px;
        }

        .checkbox-item {
            margin-bottom: 10px;
            display: flex; // Выравниваем чекбокс и текст по одной линии
            align-items: center; // Центрируем по вертикали

            input[type="checkbox"] {
                appearance: none; // Убираем стандартный стиль чекбокса
                width: 20px; // Ширина чекбокса
                height: 20px; // Высота чекбокса
                border: 2px solid #4CAF50; // Зеленая рамка
                border-radius: 4px; // Закругленные углы
                outline: none; // Убираем обводку
                cursor: pointer; // Указатель курсора
                margin-right: 10px; // Отступ между чекбоксом и текстом
                position: relative; // Для псевдоэлемента
                transition: background-color 0.3s, border-color 0.3s; // Плавный переход

                &:checked {
                    background-color: #4CAF50; // Зеленый фон при выборе
                    border-color: #4CAF50; // Зеленая рамка при выборе

                    &::after {
                        content: ""; // Создаем псевдоэлемент как галочку
                        position: absolute;
                        top: 2px; // Настройте это значение при необходимости
                        left: 6px; // Настройте это значение при необходимости
                        width: 6px; // Ширина галочки
                        height: 10px; // Высота галочки
                        border: solid white; // Цвет галочки
                        border-width: 0 2px 2px 0; // Указываем границы для галочки
                        transform: rotate(45deg); // Поворачиваем, чтобы получить галочку
                    }
                }

                &::after {
                    content: ""; // Галочка
                    position: absolute;
                    top: 2px; // Позиция по вертикали
                    left: 6px; // Позиция по горизонтали
                    opacity: 0; // Скрыта по умолчанию
                    transition: opacity 0.2s; // Плавный переход
                }
            }

            input[type="checkbox"]:checked::after {
                opacity: 1; // Галочка видима при выборе
            }

            label {
                font-size: 1rem; // Размер шрифта метки
                color: #333; // Цвет текста
                cursor: pointer; // Указатель курсора
            }
        }
    }
}