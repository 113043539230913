.oneLoan {
    &__wrapper {
        margin-top: 20px;
        height: 100px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-img {
            height: 100px;
            width: 100px;
            position: relative; /* Чтобы использовать абсолютное позиционирование для внутреннего img */
            img {
                height: 80px;
                width: 80px;
                position: absolute; /* Позволяет позиционировать изображение относительно контейнера */
                top: 50%;          /* Перемещаем его в 50% от высоты контейнера */
                left: 50%;        /* Перемещаем его в 50% от ширины контейнера */
                transform: translate(-50%, -50%); /* Центрируем по обеим осям */
            }
        }
        &-percent {
            width: 25%;
            h2 {
                font-weight: 400;
                font-size: 18px;
                padding-right: 20px;
            }
        }
        &-money {
            width: 25%;
            h2 {
                font-weight: 400;
                font-size: 18px;
            }
        }
        &-name {
            width: 40%;
            h2 {
                font-weight: 400;
                font-size: 20px;
            }
        }
        
        a {
            background-color: orange;
            border-radius: 10px;
            border: none;
            width: 100px;
            height: 50px;
            margin-right: 30px;
            color: white;
            text-decoration: none;
            text-align: center;
            display: inline-block;
            line-height: 50px;
        }

    }
}

@media (max-width: 768px) {
    .oneLoan {
        &__wrapper {
            display: flex; /* Сохраняем Flexbox */
            flex-direction: column; /* Вертикальное выравнивание */
            align-items: center; /* Центрируем содержимое */
            height: auto; /* Не зафиксировав высоту, позволит содержимому растягиваться */
            
            &-name, &-percent, &-money {
                text-align: center;
                width: 100%;
                h2 {
                    margin-right: 0px !important;
                }
            }

            a {
                display: inline-block;
                width: 80%;
                margin: 10px 0; /* Отступ сверху и снизу для лучшего centrирования */
            }
        }
    }
}

// Адаптация для планшетов
@media (max-width: 1024px) {
    .oneLoan {
        &__wrapper {
            &-name {
                h2 {
                    font-size: 16px;
                    margin-right: 10px;
                }
            }
            &-money {
                h2 {
                    font-size: 16px;
                    margin-right: 10px;
                }
            }
            &-percent {
                h2 {
                    font-size: 16px;
                }
            }
        }
    }
}