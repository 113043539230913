.creditCard {
    width: 100%;
    margin-bottom: 20px;
    &__wrapper {
        text-align: left;
        &-buttons {
            margin-top: 20px;
            button {
                margin-right: 20px;
                border-radius: 20px;
                border: 1px solid rgb(206, 202, 202);
                height: 40px;
                padding: 0 20px;
                background-color: rgba(255, 255, 255, 0);
                cursor: pointer;
            }
        }
        &-inputs {
            margin-top: 30px;
            input {
                border-radius: 10px;
                padding: 10px;
                border: 1px solid rgb(206, 202, 202);
                margin-right: 10px;
                height: 30px;
            }
            button {
                border-radius: 10px;
                padding: 0px 20px;
                height: 50px;
                background-color: orange;
                border: none;
                color: white;
                cursor: pointer;
            }
        }
    }
    .filter-button {
        display: none; /* По умолчанию не показывать кнопку */
    }
}

// Мобильные устройства
@media (max-width: 768px) {
    .creditCard input {
        margin-top: 10px;
        width: 80% !important;
        margin-bottom: 10px;
    }
    .sort-container {
        display: block !important;
    }
    .creditCard__wrapper-inputs {
        margin-top: 0px;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;
    }
    .filter-button {
        display: none;
        //display: inline-block; /* Показывать кнопку только на мобильных устройствах */
        margin-left: 10px;
        border-radius: 10px;
        padding: 0px 20px;
        height: 50px;
        background-color: orange;
        border: none;
        color: white;
        cursor: pointer;
    }
    
    .creditCard__wrapper-inputs.hidden {
        max-height: 0;
    }
}

// Адаптация для планшетов
@media (max-width: 1024px) {
    .creditCard {
        input {
            width: 20%;
        }
    }
}