.homePage { // Максимальная ширина контейнера
    margin: 20px auto; // Центрируем контейнер на странице
    padding: 20px; // Отступ внутри контейнера
    background-color: white;    border-radius: 8px; // Закругленные углы
    font-family: Arial, sans-serif; // Шрифт для всей страницы

    h1 {
        font-size: 2rem; // Размер шрифта заголовка
        margin-bottom: 20px; // Отступ снизу
        color: #4CAF50; // Цвет заголовка
        text-align: center; // Выравнивание по центру
    }
    h2 {
        color: #4CAF50; // Цвет заголовка
    }

    p {
        text-align: center;
        font-size: 1rem; // Размер шрифта параграфа
        line-height: 1.6; // Высота строки для лучшей читабельности
        margin-bottom: 15px; // Отступ между параграфами
        color: #333; // Цвет текста
    }

    br {
        margin: 15px 0; // Отступ для переносов строк
    }
    
    // Стили для разделов
    section {
        margin-top: 20px; // Отступ сверху для каждого нового раздела
        padding: 15px; // Отступ внутри секций
        background-color: #fff; // Белый фон для секций
        border-radius: 5px; // Закругленные углы
    }
}
@media (max-width: 768px) {
    .homePage {
        margin: 0 auto;
        margin-bottom: 20px;
    }
}