.questions {
    margin: 20px auto; // Центрируем контейнер на странице
    padding: 20px; // Отступ внутри контейнера
    background-color: white;    border-radius: 8px; // Закругленные углы
    font-family: Arial, sans-serif; // Шрифт для всей страницы

    h1 {
        font-size: 2rem; // Размер шрифта заголовка
        margin-bottom: 20px; // Отступ снизу
        color: #4CAF50; // Цвет заголовка
        text-align: center; // Выравнивание по центру
    }

    p {
        font-size: 1rem; // Размер шрифта параграфа
        line-height: 1.6; // Высота строки для лучшей читабельности
        margin-bottom: 15px; // Отступ между параграфами
        color: #333; // Цвет текста
    }

    strong {
        font-weight: bold; // Жирный шрифт для выделения текста
        color: #4CAF50; // Цвет выделенного текста
    }

    hr {
        margin: 20px 0; // Отступы вокруг для разделителей
        border: none; // Убираем стандартные линии
        background-color: #ddd; // Цвет для линии разделителя
        height: 1px; // Высота для линии разделителя
    }
}

@media (max-width: 768px) {
    .questions {
        margin: 0 auto;
        margin-bottom: 20px;
    }
}