.zaim {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    &__wrapper {
        padding-left: 20px;
        text-align: left;
        &-buttons {
            margin-top: 20px;
            button {
                margin-bottom: 10px;
                margin-right: 20px;
                border-radius: 20px;
                border: 1px solid rgb(206, 202, 202);
                height: 40px;
                padding: 0 20px;
                background-color: rgba(255, 255, 255, 0);
                color: black;
            }
        }
        &-inputs {
            margin-top: 30px;
            input {
                margin-bottom: 10px;
                border-radius: 10px;
                padding: 10px;
                border: 1px solid rgb(206, 202, 202);
                margin-right: 10px;
                height: 30px;
            }
            button {
                border-radius: 10px;
                padding: 0px 20px;
                height: 50px;
                background-color: orange;
                border: none;
                color: white;
                cursor: pointer;
            }
        }
    }
    
}

.sort-container {
    display: flex;
    align-items: center;
    justify-content: space-between; // Расположение по краям
}

.sort-header {
    display: flex;
    align-items: center;
}

.dropdown {
    position: relative; // Для размещения dropdown относительно кнопки
    margin-left: 10px; // Отступ между заголовком и кнопкой
}

.dropdown-menu {
    position: absolute;
    top: 100%; // Под кнопкой
    left: 0;
    background: white; // Фоновый цвет
    border: 1px solid #ccc; // Граница
    border-radius: 10px; // Закругление углов
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); // Тень
    z-index: 1000; // Чтобы dropdown был поверх других элементов
    display: flex;
    flex-direction: column; // Вертикальное расположение пунктов
}

// Стили для кнопки в dropdown
.sort-button {
    padding: 8px 16px; // Отступы кнопки
    background-color: orange; // Цвет фона кнопки
    color: white; // Цвет текста
    border: none; // Без границы
    border-radius: 10px; // Закругление углов кнопки
    cursor: pointer; // Курсор-указатель
    font-size: 16px; // Размер шрифта
    transition: background-color 0.3s; // Плавный переход при наведении
    &:hover {
        background-color: orange; // Цвет при наведении
    }
}

// Стили для кнопок в dropdown
.dropdown-menu button {
    color: black;
    padding: 8px 16px; // Отступы кнопки
    text-align: left; // Выравнивание текста
    background: none; // Без фона
    border: none; // Без границы
    cursor: pointer; // Курсор-указатель
    &:hover {
        background: #f0f0f0; // Фон при наведении
    }
}

@media (max-width: 768px) {
    .zaim {
        &__wrapper {
            &-buttons {
                display: none;
            }
            padding-left: 0px;
            .user {
                display: none;
            }
        }
        display: block;
        .checkbox__wrapper {
            padding: 20px !important;
        }
        .checkbox {
            display: none;
            width: 100%;
        }
        &__wrapper-inputs {
            input {
                display: none;
                width: 80%;
            }
            button {
                display: none;
                width: 80%;
            }
        } 
    }
}

// Адаптация для планшетов
@media (max-width: 1024px) {
    .zaim {
        &__wrapper {
            &-buttons {
                button {
                    margin-bottom: 10px;
                }
            }
            &-inputs {
                input {
                    margin-bottom: 10px;
                }
            }
        }
        .checkbox {
            &__wrapper {
                padding: 0;
                text-align: center;
            }
        }
    }
}