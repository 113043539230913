.credit {
    width: 100%;
    margin-bottom: 20px;
    &__wrapper {
        text-align: left;
        &-buttons {
            margin-top: 20px;
            button {
                margin-right: 20px;
                border-radius: 20px;
                border: 1px solid rgb(206, 202, 202);
                height: 40px;
                padding: 0 20px;
                background-color: rgba(255, 255, 255, 0);
                cursor: pointer;
            }
        }
        &-inputs {
            margin-top: 30px;
            input {
                border-radius: 10px;
                padding: 10px;
                border: 1px solid rgb(206, 202, 202);
                margin-right: 10px;
                height: 30px;
            }
            button {
                border-radius: 10px;
                padding: 0px 20px;
                height: 50px;
                background-color: orange;
                border: none;
                color: white;
            }
        }
    }
}