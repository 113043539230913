.loans {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    &__wrapper {
        padding-left: 20px;
        text-align: left;
        &-buttons {
            margin-top: 20px;
            button {
                margin-bottom: 10px;
                margin-right: 20px;
                border-radius: 20px;
                border: 1px solid rgb(206, 202, 202);
                height: 40px;
                padding: 0 20px;
                background-color: rgba(255, 255, 255, 0);
                color: black;
            }
        }
        &-inputs {
            margin-top: 30px;
            input {
                margin-bottom: 10px;
                border-radius: 10px;
                padding: 10px;
                border: 1px solid rgb(206, 202, 202);
                margin-right: 10px;
                height: 30px;
            }
            button {
                border-radius: 10px;
                padding: 0px 20px;
                height: 50px;
                background-color: orange;
                border: none;
                color: white;
                cursor: pointer;

            }
        }
    }
    .filter-button {
        display: none; /* По умолчанию не показывать кнопку */
    }
}

@media (max-width: 768px) {
    .loans {
        .checkbox {
            display: none;
        }
        &__wrapper {
            padding-left: 0px;
            .user {
                display: none;
            }
            &-buttons {
                display: none;
            }
        }
        display: block;
        .checkbox__wrapper {
            padding: 20px !important;
        }
        .checkbox {
            width: 100%;
        }
        &__wrapper-inputs {
            display: none;
            input {
                width: 80%;
            }
            button {
                width: 80%;
            }
        } 
    }
}

// Адаптация для планшетов
@media (max-width: 1024px) {
    .loans {
        &__wrapper {
            &-buttons {
                button {
                    margin-bottom: 10px;
                }
            }
            &-inputs {
                input {
                    margin-bottom: 10px;
                }
            }
        }
        .checkbox {
            &__wrapper {
                padding: 0;
                text-align: center;
            }
        }
    }
}