.header {
    background-color: white;
    width: 90%;
    margin: 0 auto;
    min-height: 150px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1600px;
    &__wrapper {
        padding: 30px 0px;
        align-items: center;
        &-top {
            display: flex;
            img {
                height: 50px;
                margin-right: 2%;
            }
            .header__wrapper-descr {
                height: 47px;
                //margin-right: 2%;
                display: flex;
                width: 220px;
                margin-right: auto;
                align-items: center;
                text-align: left;
                h1 {
                    font-size: 15px;
                    font-weight: 400;
                }
            }
            input {
                padding: 0px 30px;
                width: 25%;
                border-radius: 10px;
                font-size: 15px;
                border: 1px solid rgb(167, 162, 162);
            }
            button {
                height: 50px;
                max-width: 270px;
                background-color: #018B13;
                color: white;
                font-size: 15px;
                padding: 0 20px;
                border-radius: 10px;
                border-style: none;
                cursor: pointer;
            }
        }
        .burger {
            display: none;
        }
        .header__divider {
            margin-top: 20px;
            border-top: 2px solid #bbb;
            margin-bottom: 20px;
        }
        &-bottom {
            display: flex;
            justify-content: space-between;
            a {
                font-weight: 600;
                text-decoration: none;
                color: black;
            }
        }

    }
    .header__wrapper-bottom a {
        text-decoration: none;
        color: black; /* Цвет для обычного состояния */
        margin-right: 20px; /* Отступ между ссылками */
        position: relative; /* Обязательно добавьте позицию */
    }
    
    .header__wrapper-bottom .active {
        color: #000000; /* Цвет активной ссылки */
        font-weight: bold; /* Жирный шрифт для активной ссылки */
    }
    
    /* Псевдоэлемент для подчеркивания */
    .header__wrapper-bottom a::after {
        content: '';
        display: block;
        width: 100%; /* Чтобы линия занимала всю ширину ссылки */
        height: 2px; /* Высота линии */
        background-color: #018B13; /* Цвет линии */
        position: absolute;
        bottom: -5px; /* Сместите вниз (-5px или нужное вам значение) */
        left: 0;
        right: 0;
        transform: scaleX(0); /* Изначально линия скрыта */
        transition: transform 0.3s ease; /* Анимация при появлении линии */
    }
    
    .header__wrapper-bottom a:hover::after,
    .header__wrapper-bottom .active::after {
        transform: scaleX(1); /* Показать линию при наведении или когда ссылка активна */
    }
    .menu-button {
        display: none; // Скрыть кнопку меню на больших экранах
        background-color: #018B13;
        color: white;
        padding: 10px;
        margin-bottom: 20px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
    }

    .header__wrapper-bottom {
        display: flex; // По умолчанию показываем ссылки
        justify-content: space-between; // Для горизонтального расположения
        flex-direction: row; // Вертикальное расположение для мобильных устройств
        transition: max-height 0.3s ease; // Плавная анимация при открытии

        // Переопределяем стили для открытого меню на мобильных устройствах
        &.open {
            max-height: 300px; // Задайте максимальную высоту для открытого меню
        }
    }
    .menu-button {
        display: none; // Скрыть кнопку меню на больших экранах
        background-color: #62816600;
        color: rgb(0, 0, 0);
        padding: 10px;
        border: 1px solid black;
        border-radius: 5px;
        cursor: pointer;
        position: relative; // Для позиционирования стрелки
    }
    .search__button {
        display: block;
        margin-right: 20px;
            width: 40px !important;
            background-color: rgba(255, 255, 255, 0);
            color: black;
            svg {
                width: 1.5em;
                height: 1.5em;
            }
    }
    .mobile-search-input {
        display: block; // Сделаем инпут видимым по умолчанию в десктопной версии
        height: 35px;
        width: 40px;
        margin-right: 5px;
    }
}

// Мобильные устройства
@media (max-width: 768px) {
    .header {
        .container {
            width: 95%;
        }
        width: 100%;
        margin-top: 0px;
        min-height: 70px;
        border-radius: 0px;
        &__wrapper {
            padding: 0 0 0 0;
        }
        &__wrapper-top {
            align-items: center; // Центрирование
            img {
                margin-right: auto !important;
                margin-top: 15px;
                height: 35px !important;
            }
            button {
                padding: 0;
                max-width: 80px !important;
            }
            input {
                margin-left: 10px;
                display: block;
                height: 35px !important;
            }
        }
        &__wrapper-descr {
            display: none !important;
            h1 {
                display: none;
                text-align: center;
                margin: 0 auto;
            }
        }
        
        button {
            margin-right: 1.5%;
            margin-top: 15px;
            width: 15%;
            height: 37px; 
            border-radius: 5px;// Увеличиваем ширину для мобильных устройств
        }
        input {
            padding: 0 5px;
            margin-right: 0px;
            margin-top: 15px;
            width: 20%;
            height: 37px; 
            border-radius: 5px;
        }

        &__wrapper-bottom {
            flex-direction: column; // Вертикальное расположение
            align-items: center; // Центрирование
            a {
                margin-bottom: 10px;
            }
        }
        .menu-button {
            padding: 0;
            height: 37px;
            margin: 0 auto;
            margin-top: 15px;
            display: block; 
            border-radius: 5px;// Отображаем кнопку меню на мобильных устройствах
        }
        
        .header__wrapper-bottom {
            margin-top: 10px;
            flex-direction: column; // Вертикальное расположение для мобильных устройств
            max-height: 0; // Скрыть меню на мобильных устройствах при загрузке
            overflow: hidden; // Прятать лишние элементы
            transition: max-height 0.3s ease;
            a {
                margin-right: 0;
            }
        }
        .header__divider {
            display: none;
        }
        .burger {
            margin-right: 0;
            border: 1px solid rgb(110, 108, 108);
            display: block;
            max-width: 40px !important;
            background-color: rgba(255, 255, 255, 0);
            color: black;
            svg {
                width: 1.8em;
                height: 1.8em;
            }
        }
        .burger:after {
            background-color: rgba(255, 255, 255, 0);
            color: black;
        }
        .search__button {
            margin-right: 0px;
            display: block;
            width: 35px !important;
            background-color: rgba(255, 255, 255, 0);
            color: black;
            svg {
                width: 1.5em;
                height: 1.5em;
            }
        }
        
    }   
}

// Адаптация для планшетов
@media (max-width: 1024px) {
    .header {
        &__wrapper {
            &-top {
                align-items: center;
                img {
                    height: 50px;
                    margin-right: 2%;
                }
                .header__wrapper-descr {
                    width: 270px;
                }
                input {
                    height: 47px;
                }
            }   
        }
    }
}